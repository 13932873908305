<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import {paginationHelper} from "@/helpers/pagination-helper";
import Swal from "sweetalert2";
import {routerHelper} from "@/helpers/router-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import axios from "axios";
import {required} from "vuelidate/lib/validators";
import EcatCkeditor from "../../../../components/ecat-ckeditor.vue";
import {dateUtil} from "../../../../helpers/date-util";
import {imageHelper} from "../../../../helpers/image-helper";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import {pdfHelper} from "../../../../helpers/pdf-helper";
import {queryHelper} from "../../../../helpers/query-helper";

export default {

  components: {
    EcatCkeditor,
    Layout,
    PageHeader,
    VuePdfEmbed,
  },

  computed: {
    pdfHelper() {
      return pdfHelper
    },
    paginationHelper() {
      return paginationHelper
    }
  },

  data() {
    return {
      submitted: false,
      languageId: "",
      pdfFile: null,
      // instance: null,
      customPath: {
        type: "CUSTOM",
        contentType: "TEXT",
        content: ""
      },

      contentTypes: [],
      pathTypes: [],

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        inputPage: "",
        isBusy: false,

        items: []
      }
    };
  },

  validations() {
    let textRequired = {}
    if (this.customPath.contentType === "TEXT" || this.customPath.contentType === "HTML_TO_PDF") {
      textRequired = {
        content: { required }
      }
    }

    return {
      customPath: {
        path: {required},
        type: { required },
        contentType: { required },
        ...textRequired
      }
    }
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: "Własne ścieżki",
          active: true
        }
      ]
    },

    async loadCustomPath(id) {
      try {
        const {data} = await axios.get(`/custom-path/${id}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.customPath = data
        // if (this.customPath.contentType === "PDF_URL") {
        //   this.pdfFile = this.customPath.pdfUrl
        // }
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    // handleLoaded(instance) {
    //   this.instance = instance
    // },

    async save() {
      this.submitted = true

      this.$v.customPath.$touch();
      if (this.$v.customPath.$invalid) {
        return;
      }

      const formData = new FormData();
      if (this.customPath && this.customPath.id) {
        formData.append("id", this.customPath.id)
      }

      formData.append("languageId", this.languageId);
      formData.append("path", this.customPath.path)
      formData.append("type", this.customPath.type)
      formData.append("contentType", this.customPath.contentType)

      if (this.customPath.contentType === "PDF_URL") {
        formData.append("pdfFile", this.pdfFile)
      } else if (this.customPath.contentType === "TEXT" || this.customPath.contentType === "HTML_TO_PDF") {
        formData.append("content", this.customPath.content)
      }

      try {
        const { data } = await axios.put(`/custom-path`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        this.customPath = data

        const query = {}
        query["id"] = this.customPath.id

        queryHelper.set(query)
        await Swal.fire("Sukces!", "Zapisano!", "success")
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadContentTypes() {
      try {
        const {data} = await axios.get(`/custom-path/content-types`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.contentTypes = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadPathTypes() {
      try {
        const {data} = await axios.get(`/custom-path/path-types`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.pathTypes = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getFields() {
      return [
        { key: "path", label: "Ścieżka"},
        { key: "type", label: "Typ", formatter: value => this.$t(`custom-path.path-type.${value}`)},
        { key: "contentType", label: "Typ pliku", formatter: value => this.$t(`custom-path.content-type.${value}`)},
        { key: "createdAt", label: "Data utworzenia", formatter: value => dateUtil.asDateTime(value) },
        { key: "archivedAt", label: "Data archiwizacji", formatter: value => dateUtil.asDateTime(value) },
        { key: "action", slot: true, label: this.$t('table.actions') }
      ]
    },

    async loadCustomPaths() {
      let page = this.table.currentPage - 1;
      if (page > 0) {
        page = this.table.currentPage * this.table.perPage - this.table.perPage;
      }

      const { data } = await axios.get(`/custom-path/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          page: page,
          size: this.table.perPage,
          languageId: this.languageId,
          path: this.customPath.path
        }
      });

      const { customPaths, count } = data

      this.table.items = customPaths
      this.table.totalRows = count
      this.table.rows = count

      return this.table.items;
    },

    openCustomPathModal(customPath) {
      window.location = `/dashboard/admin/custom-paths/create-or-edit/${this.languageId}` + (customPath ? `?id=${customPath.id}` : '')
    },

    loadPdfFile(input) {
      imageHelper.fetchFromInput(input).then((result) => {
        if (!result) {
          return;
        }

        const {file} = result
        console.log(file)
        this.pdfFile = file
      }).catch((error) => {
        console.error('Error occurred:', error);
      });
    }
  },

  async created() {
    const languageId = this.$route.params.languageId;
    if (!languageId) {
      await this.$router.push('/dashboard/home').then(() => {
        Swal.fire("", "Nie znaleziono takiego języka!", "error");
      }).catch((error) => {
        if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
          console.log(error)
        }
      })
      return;
    }

    this.languageId = languageId

    await this.loadContentTypes()
    await this.loadPathTypes()

    const params = routerHelper.params();
    if (params && params.id) {
      await this.loadCustomPath(params.id)
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader title="Własne ścieżki" :items="getItems()" />
    <div class="row">
      <div class="col-4" />

      <div class="col-4">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <label>Ścieżka</label>
              <input v-model="customPath.path" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.customPath.path.$error }" :disabled="!!customPath.id" />
              <div v-if="submitted && !$v.customPath.path.required" class="invalid-feedback">{{ $t('message.required')}}</div>
            </div>

            <div class="form-group">
              <label>Typ ścieżki</label>
              <vue-multiselect
                  v-model="customPath.type"
                  :options="pathTypes"
                  :custom-label="value => $t(`custom-path.path-type.${value}`)"
                  placeholder="Wybierz typ ścieżki"
                  :class="{ 'is-invalid': $v.customPath.type.$error }"
                  :allow-empty="false"
                  :show-labels="false"
                  :disabled="!!customPath.id"
              />
              <div v-if="submitted && !$v.customPath.type.required" class="invalid-feedback">{{ $t('message.required')}}</div>
            </div>

            <div class="form-group">
              <label>Typ zawartości</label>
              <vue-multiselect
                  v-model="customPath.contentType"
                  :options="contentTypes"
                  :custom-label="value => $t(`custom-path.content-type.${value}`)"
                  placeholder="Wybierz typ zawartości"
                  :class="{ 'is-invalid': $v.customPath.contentType.$error }"
                  :allow-empty="false"
                  :show-labels="false"
                  :disabled="!!customPath.id"
              />
              <div v-if="submitted && !$v.customPath.contentType.required" class="invalid-feedback">{{ $t('message.required')}}</div>
            </div>

            <div class="form-group text-center">
              <b-button @click="save" variant="success" :disabled="customPath.archived">{{  $t('message.save') }}</b-button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4" />
    </div>

    <template v-if="customPath.contentType === 'PDF_URL'">
      <div class="text-center">
        <input accept="application/pdf" type="file" id="send-pdf" @input="loadPdfFile" hidden />
        <label for="send-pdf" class="btn btn-primary">Wybierz plik PDF</label>
      </div>

      <div class="py-4" v-if="customPath.pdfUrl">
        <h3 class="text-center">Podgląd pliku PDF z systemu</h3>

        <div class="app-content">
          <vue-pdf-embed :source="pdfHelper.correctUrl(customPath.pdfUrl)" />
        </div>
      </div>
    </template>
    <template v-else>
      <ecat-ckeditor v-model="customPath.content" :class="{ 'is-invalid': submitted && $v.customPath.content.$error }" />
      <div v-if="submitted && !$v.customPath.content.required" class="invalid-feedback">{{ $t('message.required')}}</div>
    </template>

    <div class="row py-4" v-if="customPath.id">
      <div class="col-12">
        <h4>Zarchiwizowane zmiany</h4>

        <ecat-table
            ref="table"
            :table="table"
            :fields="getFields()"
            :items="loadCustomPaths"
            :pagination-top="true">
          <template v-slot:action="{ item }">
            <div class="button-items">
              <b-button variant="primary" class="btn-sm" @click="openCustomPathModal(item)">Podgląd</b-button>
            </div>
          </template>
        </ecat-table>
      </div>
    </div>

  </Layout>
</template>

<style scoped>
.pdf-container {
  height: 100vh;
}
</style>